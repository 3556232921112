<template>
  <div class="personalCenter">
    <div class="info">
      <div style="display: flex;flex-direction: column; justify-content: center; text-align: center;">
        <!-- <div class="userImgFather"> -->
        <img class="userImg" :src="defaultImg" alt="" />
        <!-- infoObj.u_avatar != '' ? infoObj.u_avatar :  -->
        <!-- </div> -->
        <span class="UID" v-if="infoObj.u_id">UID:{{ infoObj.u_id }}</span>
      </div>

      <p class="userName">
        <span class="loginButton" @click="toLogin" v-if="infoObj.u_id == ''">
          登录/注册
        </span>
        <span class="nameText"> {{ infoObj.u_nickname ? infoObj.u_nickname : '----' }}
          <span class="realName">{{ infoObj.u_real_name ? '已实名' : '未实名' }}：{{ infoObj.u_real_name +
            infoObj.u_id_card }}</span>
        </span>
        <span class="signature">{{ infoObj.u_signature }}</span>

        <span class="regist_time">{{ infoObj.u_regist_time }}</span>
      </p>

      <div class="ua_balance">
        <div>
          <p class="balanceTitle">余额</p>
          <p class="balance">{{ infoObj.ua_balance ? infoObj.ua_balance : '--.--' }}</p>
        </div>
        <button class="withdrawalButton" @click="toWithdrawal">提现</button>
      </div>
    </div>

    <div class="personalCenterMain">
      <van-cell-group>
        <!-- <van-cell
          title="余额"
          is-link
          :value="infoObj.ua_balance?'infoObj.ua_balance':'0.00'"
          @click="toWithdrawal"
        /> -->
        <van-cell title="自动打款">
          <van-switch @change="changePaymentStatus" size="20px" :active-value="1" :inactive-value="0"
            active-color="#07c160" inactive-color="#ff4444" v-model="infoObj.u_payment_status" />

        </van-cell>
        <van-cell title="手机" :value="infoObj.u_phone != '' ? infoObj.u_phone : '未设置'" @click="toBindPhone" />
        <van-cell title="QQ" :value="infoObj.u_qq != '' ? infoObj.u_qq : '未设置'" @click="toChangeInfo" is-link />
        <van-cell title="支付宝真实姓名" :value="infoObj.u_ali_realname != '' ? infoObj.u_ali_realname : '未设置'"
          @click="toChangeInfo" is-link />
        <van-cell title="支付宝账号" :value="infoObj.u_ali_account != '' ? infoObj.u_ali_account : '未设置'" @click="toChangeInfo"
          is-link />
        <van-cell title="前往实名认证" is-link @click="toRealName" />
        <van-cell title="提现记录" is-link @click="totransferRecord" />
        <van-cell title="打款记录" is-link @click="toRemitRecord" />
        <van-cell title="信息设置" is-link @click="toChangeInfo" />
        <van-cell title="常见问题及客服电话" is-link @click="toCommonProblem" />

      </van-cell-group>
    </div>
    <div class="personalCenterfooter" v-if="infoObj.u_id">
      <van-button type="danger" @click="loginOut">退出登录</van-button>
    </div>

    <BindPhone :showBindPhone="showBindPhone" @getUserinfo="getUserinfo" @closeShowBindPhone="closeShowBindPhone"
      ref="bindPhone" :userInfo="infoObj"></BindPhone>

  </div>
</template>

<script>
import G_time from "../../tools/time";
import BindPhone from './personalCenterComponent/bindPhone.vue'
export default {
  data() {
    return {
      defaultImg: require("../../assets/niuniulogo.png"),
      showBindPhone: false,
      infoObj: {
        u_add_time: "",
        u_ali_account: "",
        u_avatar: "",
        u_email: "",
        u_id: "",
        u_nickname: "",
        u_phone: "",
        u_regist_ip: "",
        u_regist_time: "",
        u_sex: "",
        u_signature: "",
        u_status: "",
        u_ali_realname: "",
        u_qq: "",
      },
    };
  },
  components: {
    BindPhone
  },
  mounted() {
    if (window.localStorage.getItem("token")) {
      this.getFaceCertifyResult()
      this.getUserinfo();
    }
  },
  methods: {
    changePaymentStatus(value) {
      this.$dialog.confirm({
        title: '自动打款',
        message: `即将${value == 1 ? '开启' : '关闭'}自动打款，请确认`,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      })
        .then(async () => {
          let res = await this.$axios
            .post("/api/user/updateInfo", { u_payment_status: value })

          if (res.code == "000") {
            this.$toast({
              type: "success",
              message: "修改成功",
            });

          } else {
            this.$toast.fail(res.msg)
          }
          this.getUserinfo()
        })
        .catch(() => {
          if (value == 1) {
            this.infoObj.u_payment_status = 0
          } else {
            this.infoObj.u_payment_status = 1
          }
        });
    },
    async getFaceCertifyResult() {
      let res = await this.$axios.post('/api/user/faceCertifyQuery')
    },
    // 常见问题界面
    toCommonProblem() {
      this.$router.push({
        name: "CommonProblem",
      });
    },
    getUserinfo() {
      this.$axios
        .get("/api/user/getInfo")
        .then((res) => {
          console.log(res);
          res.data.u_regist_time = G_time.ts2Date(res.data.u_regist_time);
          this.infoObj = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toRemitRecord() {
      this.$router.push({ name: "remitRecord" });
    },
    totransferRecord() {
      this.$router.push({ name: "transferRecord" });
    },
    // 跳转至提现页面
    toWithdrawal() {
      this.$router.push({
        name: "withdrawal",
        params: this.infoObj,
      });
    },
    toChangeInfo() {
      this.$router.push({
        name: "changeUserinfo",
        params: this.infoObj,
      });
    },
    toLogin() {
      this.$router.push({
        path: "/Login",
      });
    },
    toRealName() {
      this.$router.push({
        path: "/realName",
      });
    },
    toBindPhone() {
      this.showBindPhone = true
    },
    closeShowBindPhone() {
      this.showBindPhone = false
    },
    loginOut() {
      this.$dialog
        .confirm({
          title: "退出登录确认",
          message: "即将推出登录，是否继续",
        })
        .then((res) => {
          console.log(res);
          this.$axios
            .get("/api/user/logout")
            .then((res) => {
              if (res.code == "000") {
                window.localStorage.setItem("token", "");
                this.$store.dispatch("setUserToken", "");
                this.$router.push({
                  path: "/Login",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("退出登录");
    },
  },
};
</script>

<style lang='scss'>
.personalCenter {
  .ua_balance {
    height: 57px;
    width: 90%;
    position: absolute;
    background: #b59797f0;
    margin-left: 5%;
    bottom: -40px;
    border-radius: 5px;
    box-shadow: 2px 4px 5px 1px #e0dddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    p {
      margin: 5px 0 0 0;
    }

    .balanceTitle {
      margin-left: 20px;
      font-size: 17px;
      font-weight: 600;
    }

    .balance {
      margin-left: 23px;
      font-weight: 600;
    }

    .withdrawalButton {
      height: 27px;
      margin-right: 20px;
      background: #a60000;
      border: none;
      border-radius: 3px;
      padding: 3px 6px;
    }
  }

  .info {
    height: 120px;
    position: relative;
    display: flex;
    align-items: center;

    border-bottom: 1px solid #f7f8f9;
    font-size: 14px;
    background: #fef5eb;

    .userImgFather {
      margin-left: 10px;
      padding: 4px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #df5816;

    }

    .userImg {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 18px;
      border: 1px solid #df5816;
      background: #fff;
      margin-left: 10px;
    }

    .UID {
      font-size: 12px;
      margin-top: 2px;
      margin-left: 10px;
      color: #3d3c3c;
    }

    .userName {
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;

      .nameText {
        font-size: 17px;
      }

      .signature {
        width: 150px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(102, 101, 101);
      }

      .loginButton {
        display: inline-block;
        width: 70px;
        font-size: 15px;
        border: 1px solid #dc5151;
        background: #e96464;
        color: #fff;
        border-radius: 4px;
        padding: 2px;
      }

      .regist_time {
        font-size: 12px;
        color: gray;
      }
    }
  }

  .personalCenterMain {
    margin-top: 47px;
    height: 500px;
  }

  .personalCenterfooter {
    text-align: center;
  }

  .realName {
    // width: 100px;
    background: #e1dbdb;
    color: #fff;
    padding: 3px;
    font-size: 12px;
    border-radius: 3px;
  }

  .XYCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>